html {
  /* Set the base font size */
  font-size: 14px;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.full-width {
  width:100%;
}

/*
Original Bootstrap's CSS depends on empty href attributes to style cursors for several components (pagination, tabs etc.). But in AngularJS adding empty href attributes to link tags will cause unwanted route changes. This is why we need to remove empty href attributes from directive templates and as a result styling is not applied correctly. The remedy is simple, just add the following styling to your application:
Ref: http://angular-ui.github.io/bootstrap/#/buttons;
*/
.nav, .pagination, .carousel, .panel-title a { cursor: pointer; }

body { overflow:visible !important; }
#left-panel { bottom: -105px; }

/* Employee chart */
.timeLabel { fill: #CCC; }
circle.call {
  fill: transparent;
  stroke: #CCC;
  stroke-width: 1;
}
circle.call.inbound {
  fill: #F8D785;
}
circle.call.outbound {
  fill: #60B281;
}
circle.call.internal {
  fill: #AD462E;
}
g.call rect {
  stroke: #CCC;
  stroke-width: 1;
}
g.call text {
  fill: #666;
  font-size: 10px;
}
g.call.inbound rect {
  fill: #F8D785;
}
g.call.outbound rect {
  fill: #60B281;
}
g.call.internal rect {
  fill: #AD462E;
}
g.call.outbound text {
  fill: #EEE;
}
g.call.internal text {
  fill: #EEE;
}
line.activity-marker {
  stroke: #CCC;
  stroke-width: 1;
}

/** Side bar **/
.menu-item-parent {
  cursor: pointer;
}

.timeline .activity-details {
  color: #FFF;
}
.timeline .activity-details.payment {
  background: #3B9660;
}
.timeline .activity-details.csrecord {
  background: #434D8F;
}
.timeline .activity-details.account {
  background: #20566D;
}
.timeline .activity-details.bi {
  background: #CCC;
  color: #000;
}
.timeline .activity-details small {
  color: #DDD;
  margin-left: 10px;
}
.timeline .activity-details.bi small {
  color: #333;
}
.timeline .activity-details i {
  position: absolute;
  top: 30px;
  right: 20px;
  font-size: 80px;
  z-index: 0;
  color: #FFF;
  opacity: .3;
}
.timeline .activity-details.call.outbound {
  background: #60B281;
}
.timeline .activity-details.call.inbound {
  color: #000;
  background: #F8D785;
}
.timeline .activity-details.call.inbound small {
  color: #000;
}
.timeline .activity-details.call.internal {
  background: #AD462E;
}

.call-summary .ng-table-pager {
  display: none;
}
.call-summary tfoot {
  font-weight: bold;
}

.no-wrap {
  white-space: nowrap;
}

.api-log-payload {
  max-width:800px;
  word-wrap:break-word;
}

.table-sm {
  font-size: 11px;
}

.ng-table-pager .pagination {
  margin: inherit;
}
.ng-table-counts {
  margin-bottom: 10px;
}

pre.wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

/** Letters with 'tails' get cut off at the default 20px **/
.sparks .sparks-info span {
  line-height: 22px;
}

body .sparks li {
  overflow: visible;
}

/** Sliders **/
body div.slider {
  margin-bottom: 5px;
}

/** Schedule UI **/
schedule-day .list-group-item {
  border-radius: 0 !important;
  border-bottom: 0;
  border-top: 0;
  font-size: 90%;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 30px;
}
schedule-day .separator {
  height: 5px;
}
schedule-day .nighttime {
  height: 25px;
  font-size: 85%;
}
schedule-day .end-of-hour {
  border-bottom:1px solid #ddd;
}

/** Online orders **/
.online-order-card-list .label {
  margin-right: 5px;
  font-size: 90%;
}

/** Text messages **/
.text-message-content {
   max-width:500px;
   overflow-wrap: break-word;
   word-wrap: break-word;
}

.mobile-device-button {
  margin-left: 10px;
  margin-bottom: 10px;
}

/** Icon colors **/
.icon-success {
  color: #5CB85C;
}
.icon-danger {
  color: #d9534f;
}

/** Modals **/
.modal-xxl {
  min-width: 700px;
}
.modal-full {
  min-width: 95%;
}
.modal-full .modal-dialog {
  min-width: 95%;
}

/** Forms **/
.help-block.has-error {
  color: #d9534f;
}

/** Datatables **/
div.dataTables_wrapper {
  display: block !important;
}
div.dataTables_wrapper .pagination a {
  margin-left: 10px;
}
table.dataTable td {
  padding: 3px !important;
}
div.dataTables_filter {
  margin-left: 8px;
}
div.dataTables_filter input {
  margin-left: 10px;
}

/** React Data Grid **/
.rdg .rdg-row, .rdg .rdg-header-row {
  font-size: 11px;
}

/** Header small labels **/
.sparks .header-small {
  font-size: 10px;
  color: #fff;
  display: inline-block;
  margin: 1px;
  height: 22px;
  padding: 0 8px;
}

/** react-base-table **/
.BaseTable__header-row {
  background-color: inherit !important;
}

@media print {
  #main {
    position: relative;
    margin-left: 0 !important;
    width: 100% !important;
  }
  h1 {
    margin-bottom: 5px !important;
  }
  .jarviswidget {
    margin-bottom: 0 !important;
  }
  .jarviswidget .widget-body {
    min-height: auto !important;
    padding-bottom: 0 !important;
  }
  .jarviswidget>div {
    border: 0 !important;
  }
  .nav-tabs, .nav-item { display:none;}
  .dataTables_filter, .dataTables_paginate, .dataTables_info { display: none; }
}
